import { FilterState } from './types';
import { EntityMap, Parsed, NetLinkable } from '../types';

export function filterRows<F extends Parsed<NetLinkable>>(
  linkable: F[],
  filterState: FilterState,
  entities: EntityMap,
  cond: (i: F) => boolean,
): F[] {
  const { client, payer, type, activity, department, provider, block } = filterState;

  return linkable.filter((i: F) => {
    if (!cond(i)) {
      return false;
    }

    if (client && client.length > 0 && client.indexOf(i.client_id) === -1) {
      return false;
    }

    if (payer && i.payer_id !== payer) {
      return false;
    }

    if (type && type.length > 0 && type.indexOf(entities.block[i.block_id].type) === -1) {
      return false;
    }

    if (activity && activity.length > 0 && activity.indexOf(i.activity_id) === -1) {
      return false;
    }

    if (department && entities.product[i.product_id].department.id.toString() !== department) {
      return false;
    }

    if (provider && i.provider_id !== provider) {
      return false;
    }

    if (block && i.block_id !== block) {
      return false;
    }

    return true;
  });
}
