import { ReactElement } from 'react';
import { TextField, SelectField, CheckField, useField } from '@ff-it/form';
import { resetPeriodOptions, vatStrategyOptions } from 'configuration';
import { Fieldset, FormInlineError } from 'components';
import { coerceEmptyToNull, required } from 'utilities';
import { CompanyField } from 'modules/core/company/components';
import { CampaingSequenceFieldset } from './CampaingSequenceFieldset';

function BaseFields(): ReactElement | null {
  const {
    input: { value: external },
  } = useField('external', { subscription: { value: true } });
  if (external) {
    return null;
  }
  return (
    <>
      <Fieldset title="Accounting">
        <div className="row">
          <SelectField
            name="vat_strategy"
            label="VAT strategy"
            className="col-md-6"
            options={vatStrategyOptions}
            required
            validate={required}
            simple
          />
          <TextField name="onec_endpoint" label="1C Sync endpoint" className="col-md-6" {...coerceEmptyToNull} />
        </div>
      </Fieldset>
      <Fieldset title="Horizon integration">
        <div className="row">
          <TextField name="horizon_endpoint" label="Sync endpoint" className="col-md-6" {...coerceEmptyToNull} />
          <TextField name="horizon_username" label="Username" className="col-md-6" {...coerceEmptyToNull} />
          <TextField name="horizon_password" label="Password" className="col-md-6" {...coerceEmptyToNull} />
          <TextField name="horizon_organisation_pk" label="Organisation" className="col-md-6" {...coerceEmptyToNull} />
        </div>
      </Fieldset>
      <div className="row">
        <div className="col-6">
          <Fieldset title="Invoice sequence">
            <FormInlineError name="invoice_sequence" />
            <div className="form-row">
              <TextField
                name="invoice_sequence.format"
                className="col-md-6 mb-0"
                size="sm"
                placeholder="Format"
                help="YYYYMMDD-#####"
                required
              />
              <SelectField
                name="invoice_sequence.reset_period"
                placeholder="Reset period"
                className="col-md-6 mb-0"
                size="sm"
                required
                options={resetPeriodOptions}
                simple
              />
            </div>
          </Fieldset>
          <Fieldset title="Acceptance act sequence">
            <FormInlineError name="acceptance_sequence" />
            <div className="form-row">
              <TextField
                name="acceptance_sequence.format"
                className="col-md-6 mb-0"
                required
                placeholder="Format"
                help="PNA-YYYYMMDD-#####"
                size="sm"
              />
              <SelectField
                name="acceptance_sequence.reset_period"
                placeholder="Reset period"
                className="col-md-6 mb-0"
                required
                options={resetPeriodOptions}
                simple
                size="sm"
              />
            </div>
          </Fieldset>
        </div>
        <div className="col-6">
          <Fieldset title="Client agreement sequence">
            <FormInlineError name="client_agreement_sequence" />
            <div className="form-row">
              <TextField
                name="client_agreement_sequence.format"
                className="col-md-6 mb-0"
                placeholder="Format"
                help="{enterprise}-{client}-MM.YYYY/#"
                required
                size="sm"
              />
              <SelectField
                name="client_agreement_sequence.reset_period"
                placeholder="Reset period"
                className="col-md-6 mb-0"
                required
                options={resetPeriodOptions}
                simple
                size="sm"
              />
            </div>
          </Fieldset>
          <Fieldset title="Provider agreement">
            <FormInlineError name="provider_agreement_sequence" />
            <div className="form-row">
              <TextField
                name="provider_agreement_sequence.format"
                className="col-md-6"
                placeholder="Format"
                help="{enterprise}-{provider}-MM.YYYY/#"
                required
                size="sm"
              />
              <SelectField
                name="provider_agreement_sequence.reset_period"
                placeholder="Reset period"
                className="col-md-6"
                required
                options={resetPeriodOptions}
                simple
                size="sm"
              />
            </div>
            <div className="form-row">
              <div className="form-group col">
                <CheckField
                  name="direct_agreements"
                  label="Direct agreements"
                  variant="switch"
                  help="Only directly sold products are available"
                />
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <CampaingSequenceFieldset />
        </div>
      </div>
    </>
  );
}

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="row">
        <CompanyField name="company" label="Company" className="col-md-6" required includeBranches={false} />
        <TextField name="slug" label="Slug" className="col-md-3" required />
        <CheckField
          name="external"
          className="col-md-3"
          label="External agency"
          variant="switch"
          help="Not a member of system"
        />
      </div>
      <BaseFields />
    </div>
  );
}
