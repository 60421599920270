import { FetchClient, RequestArgs, RequestResult } from '@ff-it/api';
import { auth } from './auth';
import { Button, DialogBody, DialogContent, DialogFooter, DialogHeader, openModal } from '@ff-it/ui';
import { useCallback, useRef } from 'react';
import Mutex from 'utilities';

const headers: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

let reloading = false;
function reloadStaleClient(res: RequestResult<unknown, any>): void {
  const clientVersion = CLIENT_VERSION;
  if (!clientVersion || reloading) {
    return;
  }

  const serverVersion = res.response?.headers.get('X-Server-Version');

  if (serverVersion) {
    if (serverVersion !== clientVersion) {
      reloading = true;
      openModal(
        ({ onSubmit }) => (
          <DialogContent>
            <DialogHeader title="Update Available!" />
            <DialogBody>
              Version <strong data-testid="server-version">{serverVersion}</strong> is now ready.
              <br />
              Please reload to upgrade.
            </DialogBody>
            <DialogFooter>
              <Button onClick={onSubmit}>Reload</Button>
            </DialogFooter>
          </DialogContent>
        ),
        { testId: 'reload-stale-client', canDismiss: false },
      ).then(() => {
        reloading = false;
        window.location.reload();
      });
    }
  }
}

export const api = new FetchClient({
  baseURL: '/api/v1',
  headers,
  before: (r) => {
    const token = auth.token;

    r.headers.set('X-Client-Version', CLIENT_VERSION);
    if (token) {
      r.headers.set('Authorization', `Token ${token}`);
    }

    const { scope, impersonation } = auth.getSnapshot();

    const enterpriseId = scope?.kind === 'AGENCY' ? scope.id : undefined;
    const impersonationId = impersonation?.id;

    if (enterpriseId) {
      r.headers.set('X-Enterprise-Id', enterpriseId.toString());
    }

    if (impersonationId) {
      r.headers.set('X-Impersonation-Id', impersonationId.toString());
    }
  },
  after: (res: RequestResult<unknown, any>) => {
    if (res.status === 401) {
      auth.signout(() => {
        window.location.replace('/login');
      });
    } else if (res.status === 403) {
      if (res.error && res.data.detail && typeof res.data.detail === 'string') {
        res.error.message = res.data.detail;
      }
    }
    reloadStaleClient(res);
  },
});

export const fetcher = <R,>(args: RequestArgs): Promise<R> =>
  api.request<R, unknown>(args).then((res) => {
    if (res.ok) {
      return res.data;
    }
    // @FIXME: call fetch directly or have ane error types
    // error has no request data and we can't recorver from that so we just raise failure :<
    throw res;
  });

export const swrOptions = {
  fetcher,
  revalidateOnFocus: false,
} as const;

export type Fetcher = <R>(args: RequestArgs) => Promise<R>;

export function useSyncFetcher(): Fetcher {
  const mutextRef = useRef<Mutex>();
  const etagRef = useRef<string>();
  if (!mutextRef.current) {
    mutextRef.current = new Mutex();
  }

  return useCallback(async <R,>({ headers: providedHeaders, ...rest }: RequestArgs): Promise<R> => {
    const unlock = await mutextRef.current!.lock();
    let headers = providedHeaders;
    if (etagRef.current) {
      headers = { ...providedHeaders, 'If-Match': etagRef.current };
    }
    const args: RequestArgs = {
      ...rest,
      headers,
    };

    const res = await api.request<R, unknown>(args);
    if (res.ok) {
      const updatedTag = res.response.headers.get('ETag');
      if (updatedTag && updatedTag !== etagRef.current) {
        etagRef.current = updatedTag;
      }
      unlock();
      return res.data;
    }
    unlock();
    // error has no request data and we can't recorver from that so we just raise failure :<
    // @TODO prompt stale?
    throw res;
  }, []);
}
