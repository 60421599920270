import type { ReactElement } from 'react';
import eq from 'fast-deep-equal';
import { SelectField, useField } from '@ff-it/form';

import { headValueSelectProps } from 'components/Select';
import { CompanyTitle, companyFieldProps } from 'modules/core/company/components';
import { BlockWidget, Icon, activityFieldProps } from 'components';
import { departmentFieldProps } from 'modules/supplier/department/DepartmentField';
import { ReactNode, useMemo } from 'react';
import type { EntityMap } from '../types';
import { createFilterOptions } from './createFilterOptions';
import { Col, Row } from '@ff-it/ui';

const commonProps = {
  className: 'mb-0',
  simple: true,
  components: {
    DropdownIndicator: null,
  },
  size: 'sm' as const,
};

function ClientReset({ client }: { client: string[] }): ReactElement | null {
  const {
    input: { value, onChange },
  } = useField('client', {
    subscription: { value: true },
  });

  if (client.length === 0 || eq(client, value)) {
    return null;
  }
  return (
    <div className="d-flex flex-column justify-content-around">
      <Icon
        icon="rotate-left"
        size="xs"
        role="button"
        className="mx-2 text-primary"
        title="Use document clients"
        onClick={() => onChange(client)}
      />
    </div>
  );
}

export function useFilterFields(
  entities: EntityMap,
  client?: string[],
): {
  client: ReactNode;
  department: ReactNode;
  provider: ReactNode;
  block: ReactNode;
} {
  return useMemo(() => {
    const options = createFilterOptions(entities);

    return {
      client: (
        <Col>
          <Row>
            <SelectField
              name="client"
              placeholder="Clients"
              {...commonProps}
              className="flex-grow-1 mb-0"
              options={options.company}
              {...companyFieldProps}
              {...headValueSelectProps}
            />
            {client && <ClientReset client={client} />}
          </Row>
          <SelectField
            name="payer"
            placeholder="Payer"
            options={options.company}
            {...commonProps}
            {...companyFieldProps}
            formatOptionLabel={CompanyTitle}
          />
        </Col>
      ),
      department: (
        <Col>
          <SelectField
            name="department"
            placeholder="Department"
            options={options.department}
            {...departmentFieldProps}
            {...commonProps}
          />
        </Col>
      ),
      provider: (
        <Col>
          <SelectField
            name="activity"
            placeholder="Activity"
            isMulti
            isClearable={false}
            isSearchable={false}
            options={options.activity}
            {...activityFieldProps}
            {...commonProps}
          />
          <SelectField
            name="provider"
            placeholder="Provider"
            options={options.company}
            {...companyFieldProps}
            {...commonProps}
          />
        </Col>
      ),
      block: (
        <Col>
          <SelectField
            name="type"
            options={options.type}
            placeholder="Type"
            isMulti
            isClearable={false}
            {...commonProps}
          />
          <SelectField
            name="block"
            placeholder="Block"
            options={options.block}
            {...commonProps}
            getOptionValue={({ id }) => id.toString()}
            getOptionLabel={({ code }) => code}
            formatOptionLabel={BlockWidget}
          />
        </Col>
      ),
    };
  }, [entities, client]);
}
