import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/style.scss';
import * as Sentry from '@sentry/react';
import { auth } from 'services';
if (window.__RUNTIME_CONFIG__?.SENTRY_DSN) {
  Sentry.init({
    integrations: [
      // Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0,
    // replaysOnErrorSampleRate: 0,
    dsn: window.__RUNTIME_CONFIG__?.SENTRY_DSN,
    release: CLIENT_VERSION,
    environment: window.__RUNTIME_CONFIG__?.AGENCY_GROUP,
    initialScope: (scope) => {
      scope.setTags({ 'runtime-config-version': window.__RUNTIME_CONFIG__?.APP_VERSION });
      scope.setUser(auth.user);
      return scope;
    },
  });
}

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
